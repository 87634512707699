import * as React from "react";
import {
    Alert, Button, Icon, List, ListItem, Modal, PageSection, Popover, Spinner, Switch, Title, Tooltip
} from "@patternfly/react-core";
import { useTranslation } from "react-i18next";
import { ExpandableRowContent, Table /* data-codemods */, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    apiDelete, apiGet, apiPost, Cluster, ClusterResponse, Contract,
    ContractContactResponse,
    CreateFromNodeHashRequest, CreateFromNodeHashResponse,
    License, LicensesResponse, Node, UserInfo
} from "linbit-api-fetcher";
import {
    AngleDownIcon, AngleRightIcon, DownloadIcon, EditIcon, HelpIcon, PlusIcon, TrashIcon, WarningTriangleIcon
} from "@patternfly/react-icons";
import * as saveAs from "file-saver";
import { Link, useLocation } from "react-router-dom";
import { queryContracts, queryDistributions } from "./fetcher";

const buttonPadding = { paddingLeft: "0.4em", paddingRight: "0.4em" };

interface LicenseTableProps {
    contractId: number;
    cluster: Cluster;
}

const LicenseTable: React.FunctionComponent<LicenseTableProps> = (props: LicenseTableProps) => {
    const QUERY_KEY = ['contracts', props.contractId.toString(), 'clusters', props.cluster.id.toString(), 'licenses'];
    const { t, i18n } = useTranslation();

    async function fetchLicenses(contractId: number, clusterId: number): Promise<LicensesResponse> {
        let uri = '/my/contracts/' + contractId + '/clusters/' + clusterId + '/licenses';
        return apiGet<LicensesResponse>(uri);
    }

    const { isLoading, isError, data, error } =
        useQuery<LicensesResponse, string>(QUERY_KEY, () => fetchLicenses(props.contractId, props.cluster.id));

    const licenses = data ? data.list : [];

    if (isError) {
        return <Alert title="Unable to fetch contract licenses">{error}</Alert>;
    }

    if (isLoading) {
        return <Spinner  />;
    }

    const getFirstNode = () => {
        return props.cluster.nodes.length > 0 ? props.cluster.nodes[0] : null;
    }

    const onDownloadLicense = async (licenseId: number) => {
        console.log("download license", licenseId);
        // const fileName = licType.name.startsWith("RDMA") ? "drbd-transport-rdma.license" : "drbd-proxy.license";
        const fileName = "drbd-proxy.license";
        let uri = '/license-from-nodehash';
        const firstNode = getFirstNode();
        if (!firstNode) {
            alert("Cluster doesn't have any node");
            return;
        }
        const licFromNodeHash: CreateFromNodeHashRequest = {
            nodehash: firstNode.url_hash,
            mac_addresses: firstNode.mac_addresses,
            cluster_id: props.cluster.id,
            contract_id: props.contractId,
            license_id: licenseId
        };
        await apiPost<CreateFromNodeHashResponse>(uri, licFromNodeHash)
            .then(resp => {
                const license_path = resp.license_file_path ? resp.license_file_path : "/etc/linbit.license";
                const fileName = license_path.slice(license_path.lastIndexOf('/') + 1);
                saveAs(new Blob([Buffer.from(resp.license_file_content, 'base64')]), fileName);
            })
            .catch((err) => {
                const errMsg = err.toString();
                alert(errMsg);
            });
    }

    const renderLicenses = (lics: License[]) => {
        return lics.map(l => {

            return (
                <Tr key={l.id}>
                    <Td>
                        <Tooltip content={t("contracts.download-license")}>
                            <Button variant="plain" onClick={() => onDownloadLicense(l.id)} style={buttonPadding} size="sm"><DownloadIcon /></Button>
                        </Tooltip>
                    </Td>
                    <Td>{l.owner}</Td>
                    <Td>{l.license_type_id}</Td>
                    <Td>{l.expiration_date}</Td>
                    <Td>{l.date_issued}</Td>
                </Tr>
            )
        })
    }

    return (
        licenses.length > 0 ?
            <Table aria-label="licenses subtable" variant="compact">
                <Thead style={{ backgroundColor: "#ffe1bc" }}>
                    <Tr>
                        <Th colSpan={5} textCenter>{t('contracts.licenses')}</Th>
                    </Tr>
                    <Tr>
                        <Th width={10}>{t('contracts.actions')}</Th>
                        <Th width={40}>{t('contracts.owner')}</Th>
                        <Th width={10}>{t('contracts.type')}</Th>
                        <Th width={10}>{t('contracts.expires')}</Th>
                        <Th width={10}>{t('contracts.issued')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {renderLicenses(licenses)}
                </Tbody>
            </Table> : null
    )
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ExpandableRowContent: { style?: React.CSSProperties | undefined; }
        }
    }
}

interface ContractClusterTableProps {
    contractId: number;
    onDeleteNode: (contractId: number, clusterId: number, nodeId: number) => void;
}

const ContractClusterTable: React.FunctionComponent<ContractClusterTableProps> = (props: ContractClusterTableProps) => {
    const QUERY_KEY = ['contracts', props.contractId.toString(), 'clusters'];
    const { t, i18n } = useTranslation();
    const queryClient = useQueryClient();

    const [anyExpanded, setAnyExpanded] = React.useState(true);
    const [rowsExpanded, setRowsExpanded] = React.useState<{ [key: number]: boolean }>({});
    const [hideEmptyClusters, setHideEmptyClusters] = React.useState(true);

    async function fetchClusters(): Promise<ClusterResponse> {
        let uri = '/my/contracts/' + props.contractId + '/clusters';
        return apiGet<ClusterResponse>(uri);
    }

    const isRowExpanded = (clusterId: number) => {
        return clusterId in rowsExpanded ? rowsExpanded[clusterId] : true;
    }

    const onRowExpanded = (clusterId: number) => {
        const exp = { ...rowsExpanded };
        if (clusterId in rowsExpanded) {
            exp[clusterId] = !rowsExpanded[clusterId];
        } else {
            exp[clusterId] = false;
        }
        setRowsExpanded(exp);
    }

    const onCollapseAll = () => {
        const exp: { [key: number]: boolean } = {};
        for (const c of clusters) {
            exp[c.id] = !anyExpanded;
        }
        setRowsExpanded(exp);
        setAnyExpanded(!anyExpanded);
    }

    const getDistri = (distriId: number | undefined | null) => {
        if (distriId && distriId in distris) {
            return distris[distriId];
        }
        return { id: 0, name: "Unknown", show: true, strictcheck: false };
    }

    const renderNodes = (clusterId: number, nodes: Node[]) => {
        return nodes.map(n => {
            return (
                <Tr key={n.id}>
                    <Td>
                        <Tooltip content={t("contracts.download-packages")}>
                            <a href={`https://packages.linbit.com/${n.url_hash}/`} target="_blank" rel="noopener noreferrer">
                                <Button variant="plain" style={buttonPadding} size="sm"><DownloadIcon /></Button>
                            </a>
                        </Tooltip>
                        <Tooltip content={t("contracts.delete-node")}>
                            <Button
                                variant="plain"
                                onClick={() => props.onDeleteNode(props.contractId, clusterId, n.id)}
                                style={buttonPadding}
                                size="sm"><TrashIcon /></Button>
                        </Tooltip>
                        <Tooltip content={t("contracts.edit-node")}>
                            <Link to={`/contracts/${props.contractId}/clusters/${clusterId}/nodes/${n.id}`}>
                                <Button variant="plain" style={buttonPadding} size="sm"><EditIcon /></Button>
                            </Link>
                        </Tooltip>
                    </Td>
                    <Td>{n.hostname}</Td>
                    <Td>{getDistri(n.distribution_id).name}</Td>
                </Tr>
            )
        })
    }

    const renderClusters = (clusters: Cluster[]) => {
        return clusters
            .filter((c) => !hideEmptyClusters || c.nodes.length > 0)
            .map((c, index) => {
                const expanded = isRowExpanded(c.id);
                return (
                    <Tbody key={c.id} isExpanded={expanded} id={`cluster-${c.id}`}>
                        <Tr>
                            <Td expand={{
                                rowIndex: index,
                                isExpanded: expanded,
                                onToggle: () => onRowExpanded(c.id)
                            }}></Td>
                            <Td>
                                <Tooltip content={t("contracts.edit-cluster")}>
                                    <Link to={`/contracts/${props.contractId}/clusters/${c.id}`}>
                                        <Button variant="plain" style={buttonPadding} size="sm"><EditIcon /></Button>
                                    </Link>
                                </Tooltip>
                                <Tooltip content={t("contracts.add-node")}>
                                    <Link to={`/contracts/${props.contractId}/clusters/${c.id}/nodes`}>
                                        <Button variant="plain" style={buttonPadding} size="sm"><PlusIcon /></Button>
                                    </Link>
                                </Tooltip>
                            </Td>
                            <Td>{c.documentation}</Td>
                            <Td>{c.id}</Td>
                            <Td>{c.nodes.length}</Td>
                            <Td />
                        </Tr>
                        <Tr isExpanded={expanded}>
                            <Td />
                            <Td colSpan={7} style={{ paddingRight: "0" }}>
                                {/*
                                 // @ts-ignore */}
                                <ExpandableRowContent style={{ paddingTop: "0" }}>
                                    <Table aria-label="nodes subtable" variant="compact">
                                        <Thead style={{ backgroundColor: "#ffe1bc" }}>
                                            <Tr>
                                                <Th colSpan={3} textCenter>{t('contracts.nodes')}</Th>
                                            </Tr>
                                            <Tr>
                                                <Th width={10}>{t('contracts.actions')}</Th>
                                                <Th width={40}>{t('contracts.name')}</Th>
                                                <Th width={10}>{t('contracts.distribution')}</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {renderNodes(c.id, c.nodes)}
                                        </Tbody>
                                    </Table>
                                    <LicenseTable contractId={props.contractId} cluster={c} />
                                </ExpandableRowContent>
                            </Td>
                        </Tr>
                    </Tbody>
                )
            })
    }

    const qRDistris = queryDistributions();

    const { isLoading, isError, data, error } =
        useQuery<ClusterResponse, string>(QUERY_KEY, () => fetchClusters());

    const clusters = data ? data.list : [];
    const distris = qRDistris.data ? qRDistris.data : [];

    if (isError || qRDistris.isError) {
        return <Alert title="Unable to fetch contract clusters">{error}{qRDistris.error}</Alert>;
    }

    if (isLoading || qRDistris.isLoading) {
        return <Spinner  />;
    }

    return (
        <Table aria-label="cluster subtable" variant="compact">
            <Thead style={{ backgroundColor: "#fff3bb" }}>
                <Tr>
                    <Th aria-label="expand"><Button onClick={onCollapseAll} variant='plain' size="sm">{anyExpanded ? <AngleDownIcon /> : <AngleRightIcon />}</Button></Th>
                    <Th>{t('contracts.actions')}</Th>
                    <Th>{t('contracts.cluster-name')}</Th>
                    <Th>{t('contracts.cluster-id')}</Th>
                    <Th>{t('contracts.nodes')}</Th>
                    <Th>{t('contracts.hide-empty')} <Switch
                        label="hide empty clusters"
                        isChecked={hideEmptyClusters}
                        onChange={(_event, checked) => setHideEmptyClusters(checked)} />
                    </Th>
                </Tr>
            </Thead>
            {renderClusters(clusters)}
        </Table>
    )
}

interface ContractContactsCellProps {
    contractId: number;
}

const ContractContactsCell: React.FunctionComponent<ContractContactsCellProps> = (props: ContractContactsCellProps) => {

    async function fetchContractContacts(contractId: number): Promise<ContractContactResponse> {
        let uri = '/my/contracts/' + contractId + '/contacts';
        return apiGet<ContractContactResponse>(uri);
    }

    const { isLoading, isError, data, error } =
        useQuery<ContractContactResponse, string>(
            ['my', 'contract', props.contractId, 'contacts'],
            () => fetchContractContacts(props.contractId));

    if (isLoading) {
        return <Spinner  />;
    }

    if (isError) {
        return <WarningTriangleIcon />;
    }

    const emailListItems = data.list.map(cc => <ListItem key={cc.id}>{cc.email}</ListItem>);
    const emailList = <List isPlain>{emailListItems}</List>;

    return (
        <Tooltip content={emailList} isContentLeftAligned>
            <Link to={`/contracts/${props.contractId}/contacts`}>{data?.count}</Link>
        </Tooltip>
    )
}

const ContractsTable: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const queryClient = useQueryClient();

    const [isDeleteNodeOpen, setIsDeleteNodeOpen] = React.useState(false);
    const [modalErrorAlert, setModalErrorAlert] = React.useState<{ message: string } | null>(null);
    const [activeNode, setActiveNode] = React.useState<[number, number, number]>([0, 0, 0]);
    const [rowsExpanded, setRowsExpanded] = React.useState<{ [key: number]: boolean }>({});

    React.useEffect(() => {
        // get anchor links working, DOM not yet populated HACKY
        const anchor = window.location.hash.slice(1);
        if (anchor) {
            setTimeout(() => {
                const anchorEl = document.getElementById(anchor);
                if (anchorEl) {
                    anchorEl.scrollIntoView();
                }
            }, 500)
        }
    }, [location]);

    const isRowExpanded = (contractId: number) => {
        return contractId in rowsExpanded ? rowsExpanded[contractId] : true;
    }

    const onRowExpanded = (contractId: number) => {
        const exp = { ...rowsExpanded };
        if (contractId in rowsExpanded) {
            exp[contractId] = !rowsExpanded[contractId];
        } else {
            exp[contractId] = false;
        }
        setRowsExpanded(exp);
    }

    const camalize = (str: string) => {
        return str[0].toLocaleUpperCase() + str.substring(1);
    }

    const accountManagerPopupHelp = (accMgr: UserInfo) => {
        return (
            <React.Fragment>
                <List>
                    <ListItem>{t('contracts.phone')}: <a href={"tel:" + accMgr.phone_number}>{accMgr.phone_number}</a></ListItem>
                    <ListItem>{t('support.email')}: <a href={"mailto:" + accMgr.email}>{accMgr.email}</a></ListItem>
                </List>
            </React.Fragment>
        )
    }

    const renderContracts = (contracts: Contract[], accManagers: { [key: string]: UserInfo }) => {
        return contracts.map((c, index) => {
            const expanded = isRowExpanded(c.id);
            const accMgrInfo = c.account_manager_ldap && c.account_manager_ldap in accManagers ?
                accManagers[c.account_manager_ldap] : { name: '?', issuer_id: 0 };
            return (
                <Tbody key={c.id} isExpanded={expanded}>
                    <Tr>
                        <Td expand={{
                            rowIndex: index,
                            isExpanded: expanded,
                            onToggle: () => onRowExpanded(c.id),
                        }}></Td>
                        <Td>
                            <Tooltip content={t("contracts.add-cluster")}>
                                <Link to={`/contracts/${c.id}/clusters`}>
                                    <Button variant="plain" style={buttonPadding} size="sm"><PlusIcon /></Button>
                                </Link>
                            </Tooltip>
                        </Td>
                        <Td>{c.id}</Td>
                        <Td>{camalize(c.kind_name.trim())}</Td>
                        <Td>{c.customer_name}</Td>
                        <Td>
                            <Link to={`/contracts/${c.id}/tickets`}>{c.tickets} / {c.max_tickets === 0 ? "∞" : c.max_tickets}</Link>
                        </Td>
                        <Td modifier="fitContent" style={{ fontFamily: "RedHatMono", fontSize: "0.85em" }}>{c.created_at}</Td>
                        <Td modifier="fitContent" style={{ fontFamily: "RedHatMono", fontSize: "0.85em" }}>{c.support_until}</Td>
                        <Td>{accMgrInfo.name}&nbsp;
                            <Popover
                                headerContent={t('contracts.sales-manager') + ' ' + accMgrInfo.name}
                                bodyContent={accountManagerPopupHelp(accMgrInfo)}
                            >
                                <button
                                    type="button"
                                    aria-label="More info for name field"
                                    onClick={e => e.preventDefault()}
                                    aria-describedby="simple-form-name-01"
                                    className="pf-v5-c-form__group-label-help"
                                >
                                    <Icon isInline><HelpIcon /></Icon>
                                </button>
                            </Popover></Td>
                        <Td><ContractContactsCell contractId={c.id} /></Td>
                        <Td>{c.infinite_nodes ? "∞" : c.supported_nodes}</Td>
                        <Td>{c.registered_nodes}</Td>
                    </Tr>
                    <Tr isExpanded={expanded}>
                        <Td />
                        <Td colSpan={11}>
                            {/*
                             // @ts-ignore */}
                            <ExpandableRowContent style={{ paddingTop: "0" }}>
                                <ContractClusterTable contractId={c.id} onDeleteNode={onDeleteNode} />
                            </ExpandableRowContent>
                        </Td>
                    </Tr>
                </Tbody>
            )
        })
    }

    async function fetchUserInfo(uid: string | undefined | null): Promise<UserInfo> {
        let uri = '/my/user-info/' + uid;
        return apiGet<UserInfo>(uri);
    }

    const { isLoading, isError, data, error } = queryContracts();

    const contracts = data ? data.list : [];

    const queriesAccMgr = useQueries({
        queries: contracts
            .filter(contract => contract.account_manager_ldap != undefined)
            .map(contract => {
                return {
                    queryKey: ['my', 'user-info', contract.account_manager_ldap],
                    queryFn: () => fetchUserInfo(contract.account_manager_ldap),
                }
            })
    });

    if (isError) {
        return <Alert title="Unable to fetch contracts">{error}</Alert>;
    }

    if (isLoading) {
        return <Spinner  />;
    }

    const accountManagers: { [key: string]: UserInfo } = {};
    queriesAccMgr.forEach((qry, index) => {
        const ldap_id = contracts[index].account_manager_ldap;
        if (qry.data && ldap_id) {
            accountManagers[ldap_id] = qry.data;
        }
    });

    const onDeleteNode = (contractId: number, clusterId: number, nodeId: number) => {
        setIsDeleteNodeOpen(true);
        setActiveNode([contractId, clusterId, nodeId]);
    }

    const onDeleteNodeConfirmed = (contractId: number, clusterId: number, nodeId: number) => {
        setModalErrorAlert(null);
        apiDelete(`/my/contracts/${contractId}/clusters/${clusterId}/nodes/${nodeId}`)
            .then(_ => {
                queryClient.invalidateQueries(['contracts', contractId.toString(), 'clusters']);
                setIsDeleteNodeOpen(false);
            })
            .catch(reason => {
                setModalErrorAlert({ message: reason });
            })
    }

    const onCancelNodeDelete = () => {
        setIsDeleteNodeOpen(false);
        setActiveNode([0, 0, 0]);
        setModalErrorAlert(null);
    }

    return (
        <React.Fragment>
            <Table aria-label="contract table" variant="compact">
                <Thead>
                    <Tr>
                        <Th aria-label="actions" />
                        <Th>{t('contracts.actions')}</Th>
                        <Th modifier="fitContent">ID</Th>
                        <Th>{t('contracts.support-level')}</Th>
                        <Th>{t('contracts.customer')}</Th>
                        <Th>{t('contracts.tickets')}</Th>
                        <Th>{t('contracts.created')}</Th>
                        <Th>{t('contracts.expires')}</Th>
                        <Th>{t('contracts.account-manager')}</Th>
                        <Th>{t('contracts.contact-emails')}</Th>
                        <Th>{t('contracts.nodes-allowed')}</Th>
                        <Th>{t('contracts.nodes-count')}</Th>
                    </Tr>
                </Thead>
                {renderContracts(contracts, accountManagers)}
            </Table>
            <Modal
                variant="small"
                title={t('contracts.really-delete-node')!}
                titleIconVariant="warning"
                isOpen={isDeleteNodeOpen}
                onClose={onCancelNodeDelete}
                actions={[
                    <Button
                        key="confirm"
                        variant="primary"
                        onClick={() => onDeleteNodeConfirmed(activeNode[0], activeNode[1], activeNode[2])}>
                        {t('common.yes')}
                    </Button>,
                    <Button key="cancel" variant="link" onClick={onCancelNodeDelete}>
                        {t('common.cancel')}
                    </Button>
                ]}>
                {t('contracts.really-delete-node-text')}
                {modalErrorAlert && <Alert title={modalErrorAlert.message} variant="danger"></Alert>}
            </Modal>
        </React.Fragment>
    )
}

const ContractsPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();

    return (
        <PageSection>
            <Title headingLevel="h1">{t('contracts.contracts')}</Title><br />
            <ContractsTable />
        </PageSection>
    )
}

export { ContractsPage }
