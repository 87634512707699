{
    "translation": {
        "common": {
            "add": "Add",
            "save": "Save",
            "clear": "Clear",
            "back": "Back",
            "email": "Email",
            "technical": "Technical",
            "sales": "Sales",
            "error": "Error",
            "yes": "Yes",
            "cancel": "Cancel"
        },
        "contracts": {
            "contracts": "Contracts",
            "actions": "Actions",
            "support-level": "Support Level",
            "customer": "Customer",
            "created": "Created",
            "expires": "Expires",
            "account-manager": "Account Manager",
            "contact-emails": "Contact Emails",
            "nodes-allowed": "Nodes Allowed",
            "nodes-count": "Nodes Count",
            "cluster-name": "Cluster Name",
            "cluster-id": "Cluster ID",
            "nodes": "Nodes",
            "name": "Name",
            "distribution": "Distribution",
            "drbd-version": "DRBD-Version",
            "proxy-version": "Proxy-Version",
            "add-cluster": "Add Cluster",
            "edit-cluster": "Edit Cluster",
            "add-node": "Add Node",
            "edit-node": "Edit Node",
            "delete-node": "Delete Node",
            "download-packages": "Download packages",
            "download-license": "Download license",
            "owner": "Owner",
            "type": "Type",
            "issued": "Issued",
            "create": "Create",
            "sales-manager": "Your sales manager is",
            "phone": "Phone",
            "tickets": "Tickets",
            "edit-contacts": "Edit contacts for Contract",
            "really-delete-node": "Really delete Node?",
            "really-delete-node-text": "The node will be removed from cluster and contract.",
            "licenses": "Licenses",
            "hide-empty": "Hide Empty"
        },
        "edit-cluster": {
            "add": "Add Cluster to Contract",
            "edit": "Edit Cluster for Contract",
            "cluster-name": "Cluster Name"
        },
        "edit-node": {
            "add": "Add Node to Cluster",
            "hostname": "Hostname",
            "mac-addresses": "MAC Addresses",
            "ib-guids": "IB GUIDs"
        },
        "register-nodes": {
            "register-nodes": "Register Nodes",
            "header": "For managing your nodes download the manage-node command line utility",
            "download-button-text": "Download our commandline utility",
            "we-invite-you": "We invite you to use our <1>commandline tool</1> to configure the repository and register the node with LINBIT's contract database.",
            "to-use-it-save": "To use it save it to a directory (for example, /tmp), make it executable by running:",
            "interactive-mode": "Interactive Mode (default):",
            "non-interactive-mode": "Non-Interactive Mode:",
            "non-interactive-description": "Currently, this mode is limited to retrieve and to save the latest issued drbd-proxy license file. The following command should be executed as a root cron-job.",
            "script-description": "(replace /tmp with the path where you saved the script to). Please note that the script requires Python version 2.6 or later (use python --version to find out which version you are running)."
        },
        "account": {
            "account": "Account",
            "change-password": "Change Password",
            "old-password": "Old Password",
            "new-password": "New Password",
            "retype-password": "Retype Password",
            "short-password": "Password must have at least 8 characters",
            "password-must-match": "The retyped password doesn't match",
            "password-changed": "Password successfully changed.",
            "change-name": "Change Name",
            "firstname": "First name",
            "lastname": "Last name",
            "name-changed": "Name updated successfully."
        },
        "support": {
            "support": "Support",
            "ticket-paragraph": "When submitting a ticket please include a tarred gzip file (tgz) of your syslog, ha-log and relevant configuration files (/etc/drbd.d/* and /etc/ha.d/* along with any specific daemon config files ex. /etc/mysql/*).",
            "urgent-paragraph": "Open a new ticket when you have an urgent request. If you open such an urgent request by e-mail, write a new e-mail, and do not reply to a previous message. New customer input on an existing ticket does not trigger out-of-office-hours notifications for LINBIT's support agents.",
            "email": "eMail",
            "open-new-tickets": "You can open new tickets by eMail to <1>{{suppportMail}}</1>, if your eMail address is listed as a \"contact eMail\" with at least one contract, see the contracts tab.",
            "phone-support": "Phone support",
            "general-notes": "General Notes",
            "urgent-requests": "Urgent Requests",
            "office-hours": "office hours",
            "phone-support-text": "You have {{supportTime}} telephone support.",
            "no-phone-support-text": "The 24/7 phone support is not available for your contracts. Please contact your Account Manager in order to get an upgrade.",
            "choose-credential-title": "Choose your support login credential",
            "select-list-description": "Select the support credential to login with:",
            "ticket-system-title": "Web Ticket System",
            "ticket-system-text": "Use our <1>ticketing</1> software with your my.linbit.com email credentials to login. Username will be the email address associated with your account."
        },
        "downloads": {
            "downloads": "Downloads",
            "preferred-way": "The preferred way to configure the LINBIT repositories is to use the <1>Linbit Register Nodes</1> script.",
            "manual-repo-config": "Manual repo config (<strong>discouraged</strong>):",
            "node-name": "Node Name",
            "repository-url": "Repository URL",
            "config": "Config",
            "latest": "Latest"
        },
        "contact-us": {
            "contact-us": "Contact Us",
            "sales": "Sales",
            "your-sales-manager": "Your sales account manager is {{accountManager}}, <3>{{number}}</3> and <5>{{accountManagerEmail}}</5>",
            "to-open-a-ticket": "To open a ticket send an eMail to <1>{{supportMail}}</1> from one of the following eMail addresses:"
        },
        "tickets": {
            "ticketnumber": "Ticketnumber",
            "subject": "Subject",
            "accounted-time": "Accounted time",
            "status": "Status",
            "for-contract": "Ticket(s) for contract"
        },
        "reset": {
            "title": "Reset password request",
            "token": "Token",
            "set-password": "Set Password",
            "request-reset": "Request reset",
            "request-reset-description": "Request a password reset for the given email.<br />You will receive a confirmation link for the given email address and then can set a new password.",
            "reset-requested-title": "Password reset requested",
            "reset-requested-message": "An email was sent to the given address, please follow instructions in the email.",
            "reset-password-description": "With a valid token a new password can be set here.",
            "reset-password-done-title": "New password was set",
            "reset-password-done-message": "You can now go back to the login page and use your new password. Be aware that it can take up to 5 minutes to use the new password in our ticketing system."
        },
        "login": {
            "title": "Log in to your LINBIT account",
            "subtitle": "Use your LINBIT customer portal email and password",
            "keep-logged-in": "Keep me logged in for 7 days.",
            "username": "Email",
            "password": "Password",
            "login": "Log In",
            "reset-password": "Reset password?"
        }
    }
}
