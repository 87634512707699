import { Title, PageSection, ActionGroup, Alert, Button, Form, FormAlert, FormGroup, TextInput, ValidatedOptions, AlertVariant, AlertActionCloseButton, FormHelperText, HelperText, HelperTextItem } from "@patternfly/react-core";
import { ExclamationCircleIcon } from "@patternfly/react-icons";
import { apiGet, apiPut, MyAccount, ModifyMyAccount } from "linbit-api-fetcher";
import * as React from "react";
import { useTranslation } from 'react-i18next';

interface AlertStatus {
    variant: AlertVariant,
    message: string
}

const AccountPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();

    const [status, setStatus] = React.useState<AlertStatus>({ variant: AlertVariant.custom, message: '' });
    const [statusName, setStatusName] = React.useState<AlertStatus>({ variant: AlertVariant.custom, message: '' });
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [oldPassword, setOldPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [newPassword2, setNewPassword2] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [origFirstName, setOrigFirstName] = React.useState('');
    const [origLastName, setOrigLastName] = React.useState('');

    const [valPassword, setValPassword] = React.useState<ValidatedOptions>(ValidatedOptions.default);
    const [validated, setValidated] = React.useState<ValidatedOptions>(ValidatedOptions.default);

    React.useEffect(() => {
        apiGet<MyAccount>('/my/account')
            .then((resp) => {
                setOrigFirstName(resp.first_name);
                setOrigLastName(resp.last_name);
                setFirstName(resp.first_name);
                setLastName(resp.last_name);
            }).catch((reason) => {
                setStatusName({ variant: AlertVariant.warning, message: reason.toString() });
            });
    }, []);

    const onClearClick = () => {
        setOldPassword('');
        setNewPassword('');
        setNewPassword2('');
    }

    const onSubmitClick = () => {
        let changepassReq = {
            old_password: oldPassword,
            new_password: newPassword,
        };
        setIsSubmitting(true);
        apiPut('/my/account/change-password', changepassReq)
            .then((_) => {
                onClearClick();
                setStatus({ variant: AlertVariant.success, message: t('account.password-changed') });
            })
            .catch((reason) => {
                let errorMsg = reason.toString();
                if (errorMsg === "Error(4401): password doesn't match") {
                    errorMsg = "Old password incorrect.";
                }
                setStatus({ variant: AlertVariant.danger, message: errorMsg });

            })
            .finally(() => {
                setIsSubmitting(false);
            })
    }

    const onClearNameClick = () => {
        setFirstName(origFirstName);
        setLastName(origLastName);
    }

    const onSubmitNameClick = () => {
        let changeNameReq: ModifyMyAccount = {
            first_name: firstName,
            last_name: lastName,
        };
        setIsSubmitting(true);
        apiPut('/my/account', changeNameReq)
            .then((_) => {
                onClearClick();
                setStatusName({ variant: AlertVariant.success, message: t('account.name-changed') });
            })
            .catch((reason) => {
                let errorMsg = reason.toString();
                setStatusName({ variant: AlertVariant.danger, message: errorMsg });

            })
            .finally(() => {
                setIsSubmitting(false);
            })
    }

    const onNewPasswordChange = (val: string) => {
        setNewPassword(val);
        if (val !== '') {
            checkEqualPasswords(val, newPassword2);
            setValPassword(val.length < 8 ? ValidatedOptions.error : ValidatedOptions.success);
        } else {
            setValPassword(ValidatedOptions.default);
        }
    }

    const checkEqualPasswords = (password: string, password2: string) => {
        if (password === '' && password2 === '') {
            setValidated(ValidatedOptions.default)
        } else {
            setValidated(password !== password2 ? ValidatedOptions.error : ValidatedOptions.success);
        }
    }

    const onNewPassword2Change = (val: string) => {
        setNewPassword2(val);
        checkEqualPasswords(newPassword, val);
    }

    const submitDisabled = () => {
        return oldPassword === ''
            || newPassword === ''
            || newPassword2 === ''
            || validated !== ValidatedOptions.success
            || valPassword !== ValidatedOptions.success
            || isSubmitting;
    }

    return (
        <PageSection className="centered" variant="light">
            <Title headingLevel="h1" style={{ textAlign: "center" }}>{t('account.account')}</Title><br />
            <Title headingLevel="h3" className="centered">{t('account.change-name')}</Title><br />
            <Form className="centered">
                {statusName.message && (
                    <FormAlert>
                        <Alert
                            variant={statusName.variant}
                            title={statusName.message}
                            isInline
                            actionClose={<AlertActionCloseButton
                                onClose={() => setStatus({ variant: AlertVariant.custom, message: "" })} />}
                        />
                    </FormAlert>
                )}
                <FormGroup label={t('account.firstname')} fieldId='firstname'>
                    <TextInput id="firstname" aria-label="firstname input"
                        value={firstName} onChange={(_event, val) => setFirstName(val)} />
                </FormGroup>
                <FormGroup
                    label={t('account.lastname')}
                    fieldId='lastname'
                >
                    <TextInput id="lastname" aria-label="lastname input"
                        value={lastName} onChange={(_event, val: string) => setLastName(val)} />
                </FormGroup>
                <ActionGroup>
                    <Button
                        variant="primary"
                        onClick={onSubmitNameClick}
                        isDisabled={isSubmitting}>{t('account.change-name')}</Button>
                    <Button variant="secondary" onClick={onClearNameClick}>{t('common.clear')}</Button>
                </ActionGroup>
            </Form><br />
            <Title headingLevel="h3" className="centered">{t('account.change-password')}</Title><br />
            <Form className="centered">
                {status.message && (
                    <FormAlert>
                        <Alert
                            variant={status.variant}
                            title={status.message}
                            isInline
                            actionClose={<AlertActionCloseButton
                                onClose={() => setStatus({ variant: AlertVariant.custom, message: "" })} />}
                        />
                    </FormAlert>
                )}
                <FormGroup label={t('account.old-password')} fieldId='oldpassword' isRequired>
                    <TextInput id="oldpassword" aria-label="oldpassword input" type="password" isRequired
                        value={oldPassword} onChange={(_event, val) => setOldPassword(val)} />
                </FormGroup>
                <FormGroup
                    label={t('account.new-password')}
                    fieldId='newpassword' isRequired
                >
                    <TextInput id="newpassword" aria-label="newpassword input" type="password" isRequired
                        value={newPassword} onChange={(_event, val: string) => onNewPasswordChange(val)} validated={valPassword} minLength={8} />
                    {(valPassword === ValidatedOptions.error || valPassword === ValidatedOptions.warning) && (
                        <FormHelperText>
                            <HelperText>
                                <HelperTextItem icon={<ExclamationCircleIcon />} variant={valPassword}>{t('account.short-password')}</HelperTextItem>
                            </HelperText>
                        </FormHelperText>
                    )}
                </FormGroup>
                <FormGroup
                    label={t('account.retype-password')}
                    fieldId='retypepassword'
                    isRequired
                >
                    <TextInput id="retypepassword" aria-label="retypepassword input" type="password" isRequired
                        value={newPassword2} onChange={(_event, val: string) => onNewPassword2Change(val)} validated={validated} />
                    {(validated === ValidatedOptions.error || validated === ValidatedOptions.warning) && (
                        <FormHelperText>
                            <HelperText>
                                <HelperTextItem icon={<ExclamationCircleIcon />} variant={validated}>{t('account.password-must-match')}</HelperTextItem>
                            </HelperText>
                        </FormHelperText>
                    )}
                </FormGroup>
                <ActionGroup>
                    <Button
                        variant="primary"
                        onClick={onSubmitClick}
                        isDisabled={submitDisabled()}>{t('account.change-password')}</Button>
                    <Button variant="secondary" onClick={onClearClick}>{t('common.clear')}</Button>
                </ActionGroup>
            </Form>
        </PageSection>
    )
}

export { AccountPage }
