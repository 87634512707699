import './inline-edit.css';
export default {
  "button": "pf-v5-c-button",
  "inlineEdit": "pf-v5-c-inline-edit",
  "inlineEditAction": "pf-v5-c-inline-edit__action",
  "inlineEditEditableText": "pf-v5-c-inline-edit__editable-text",
  "inlineEditGroup": "pf-v5-c-inline-edit__group",
  "inlineEditInput": "pf-v5-c-inline-edit__input",
  "inlineEditLabel": "pf-v5-c-inline-edit__label",
  "inlineEditValue": "pf-v5-c-inline-edit__value",
  "modifiers": {
    "iconGroup": "pf-m-icon-group",
    "footer": "pf-m-footer",
    "column": "pf-m-column",
    "valid": "pf-m-valid",
    "plain": "pf-m-plain",
    "actionGroup": "pf-m-action-group",
    "enableEditable": "pf-m-enable-editable",
    "inlineEditable": "pf-m-inline-editable",
    "enable": "pf-m-enable",
    "bold": "pf-m-bold"
  }
};