import './masthead.css';
export default {
  "button": "pf-v5-c-button",
  "contextSelector": "pf-v5-c-context-selector",
  "dropdown": "pf-v5-c-dropdown",
  "masthead": "pf-v5-c-masthead",
  "mastheadBrand": "pf-v5-c-masthead__brand",
  "mastheadContent": "pf-v5-c-masthead__content",
  "mastheadMain": "pf-v5-c-masthead__main",
  "mastheadToggle": "pf-v5-c-masthead__toggle",
  "menuToggle": "pf-v5-c-menu-toggle",
  "modifiers": {
    "light_200": "pf-m-light-200",
    "light": "pf-m-light",
    "plain": "pf-m-plain",
    "resizeObserver": "pf-m-resize-observer",
    "fullHeight": "pf-m-full-height",
    "horizontal": "pf-m-horizontal",
    "displayStack": "pf-m-display-stack",
    "displayInline": "pf-m-display-inline",
    "insetNone": "pf-m-inset-none",
    "insetSm": "pf-m-inset-sm",
    "insetMd": "pf-m-inset-md",
    "insetLg": "pf-m-inset-lg",
    "insetXl": "pf-m-inset-xl",
    "inset_2xl": "pf-m-inset-2xl",
    "displayStackOnSm": "pf-m-display-stack-on-sm",
    "displayInlineOnSm": "pf-m-display-inline-on-sm",
    "insetNoneOnSm": "pf-m-inset-none-on-sm",
    "insetSmOnSm": "pf-m-inset-sm-on-sm",
    "insetMdOnSm": "pf-m-inset-md-on-sm",
    "insetLgOnSm": "pf-m-inset-lg-on-sm",
    "insetXlOnSm": "pf-m-inset-xl-on-sm",
    "inset_2xlOnSm": "pf-m-inset-2xl-on-sm",
    "displayStackOnMd": "pf-m-display-stack-on-md",
    "displayInlineOnMd": "pf-m-display-inline-on-md",
    "insetNoneOnMd": "pf-m-inset-none-on-md",
    "insetSmOnMd": "pf-m-inset-sm-on-md",
    "insetMdOnMd": "pf-m-inset-md-on-md",
    "insetLgOnMd": "pf-m-inset-lg-on-md",
    "insetXlOnMd": "pf-m-inset-xl-on-md",
    "inset_2xlOnMd": "pf-m-inset-2xl-on-md",
    "displayStackOnLg": "pf-m-display-stack-on-lg",
    "displayInlineOnLg": "pf-m-display-inline-on-lg",
    "insetNoneOnLg": "pf-m-inset-none-on-lg",
    "insetSmOnLg": "pf-m-inset-sm-on-lg",
    "insetMdOnLg": "pf-m-inset-md-on-lg",
    "insetLgOnLg": "pf-m-inset-lg-on-lg",
    "insetXlOnLg": "pf-m-inset-xl-on-lg",
    "inset_2xlOnLg": "pf-m-inset-2xl-on-lg",
    "displayStackOnXl": "pf-m-display-stack-on-xl",
    "displayInlineOnXl": "pf-m-display-inline-on-xl",
    "insetNoneOnXl": "pf-m-inset-none-on-xl",
    "insetSmOnXl": "pf-m-inset-sm-on-xl",
    "insetMdOnXl": "pf-m-inset-md-on-xl",
    "insetLgOnXl": "pf-m-inset-lg-on-xl",
    "insetXlOnXl": "pf-m-inset-xl-on-xl",
    "inset_2xlOnXl": "pf-m-inset-2xl-on-xl",
    "displayStackOn_2xl": "pf-m-display-stack-on-2xl",
    "displayInlineOn_2xl": "pf-m-display-inline-on-2xl",
    "insetNoneOn_2xl": "pf-m-inset-none-on-2xl",
    "insetSmOn_2xl": "pf-m-inset-sm-on-2xl",
    "insetMdOn_2xl": "pf-m-inset-md-on-2xl",
    "insetLgOn_2xl": "pf-m-inset-lg-on-2xl",
    "insetXlOn_2xl": "pf-m-inset-xl-on-2xl",
    "inset_2xlOn_2xl": "pf-m-inset-2xl-on-2xl"
  },
  "nav": "pf-v5-c-nav",
  "page": "pf-v5-c-page",
  "themeDark": "pf-v5-theme-dark",
  "toolbar": "pf-v5-c-toolbar",
  "toolbarContentSection": "pf-v5-c-toolbar__content-section",
  "toolbarExpandableContent": "pf-v5-c-toolbar__expandable-content"
};